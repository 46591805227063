<template>
  <el-dialog
    title="安装记录"
    :visible.sync="dialogVisible"
    custom-class="policy-dialog"
    width="85%"
    :before-close="handleClose"
  >
    <div>
      <el-table
        :data="tableData"
        ref="multipleTable"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="createTime" label="绑定时间"></el-table-column>
        <el-table-column prop="plate" label="绑定设备"></el-table-column>
        <el-table-column prop="frameNo" label="车架号"></el-table-column>
        <el-table-column prop="groupName" label="所属车组"></el-table-column>
        <el-table-column prop="versionName" label="产品版本"></el-table-column>
        <el-table-column prop="terminalSim" label="SIM卡ICCID"></el-table-column>
        <el-table-column prop="activationTime" label="激活日期"></el-table-column>
        <el-table-column prop="expireTime" label="服务到期"></el-table-column>
        <el-table-column prop="createTime" label="操作" width="80px">
          <template slot-scope="scope">
            <el-button
              @click="deleteItem(scope.row)"
              type="text"
              style="color: #f64a2d"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { terminalRecord, terminalRecordDelete } from "@/api/vehicleApi";
export default {
  props: {
    showDialog: Boolean,
    formData: {
      type: Object,
      default: () => { }
    },
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          this.formInline = {
            policyNo: '',
            policyHolder: '',
            insured: '',
            frameNo: ''
          };
          this.getTableData();
        } else {
          this.dialogVisible = false;
        }
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      fileList: [],
      formInline: {
        policyNo: '',
        policyHolder: '',
        insured: '',
        frameNo: ''
      },
      tableData: [],
      total: 0,
      current: 1,
      size: 10,
      oldFormInline: {},
      indemnityNum: 0
    };
  },
  methods: {
    getTableData(e) {
      terminalRecord({
        index: this.current,
        size: this.size,
        ...this.formData
      }).then(res => {
        if (res.obj && res.obj.list && res.obj.list.length) {
          this.tableData = res.obj.list;
          this.total = res.obj.total;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      }).catch(err => {
        this.tableData = [];
      });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    onReset() {
      this.current = 1;
      this.formInline = {
        policyNo: '',
        policyHolder: '',
        insured: '',
        frameNo: ''
      };
      this.getTableData();
    },
    handleCurrentChange(e) {
      this.current = e;
      this.getTableData('current');
    },
    handleSizeChange(e) {
      this.size = e;
      if (this.total < this.current * e) {
        this.current = 1;
      }
      this.getTableData('size');
    },
    handleSelectionChange(e) {
      console.log(e);
      // if (e.length) {
      //   this.fileList = [e[e.length - 1]];
      // }
      // if (e.length > 1) {
      //   // this.$message.error('只能选择一个保单！');
      //   const id = e[e.length - 1].id;
      //   const index = this.tableData.findIndex(val => val.id == id);
      //   this.$refs.multipleTable.clearSelection();
      //   this.$refs.multipleTable.toggleRowSelection(this.tableData[index], true);
      // }
    },
    handleGoPath(e) {
      this.$router.push({ path: '/routerPolicyManagement/ConstructionMachinery/policyDetails/' + e.id });
    },
    deleteItem(e) {
      this.$confirm('此操作将删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            terminalRecordDelete({
              id: e.id
            }).then(res=>{
              this.$message.success('删除成功！');
              this.getTableData();
            })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    }
  }
};
</script>

<style lang="scss">
.policy-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
  }
  &-template {
    height: 60px;
    line-height: 60px;
    background: #f3f8ff;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    border: 1px dashed #d1e3fe;
    padding: 0 24px;
    margin-bottom: 12px;
    &-span {
      color: #0080ff;
      cursor: pointer;
    }
  }
}
</style>