<template>
  <el-dialog
    title="添加"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    destroy-on-close
    append-to-body
    width="40%"
  >
    <el-form ref="dataForm" :model="dataForm" :rules="rules"
      ><el-form-item prop="terminalNo">
        <TextInput
          v-model="dataForm.terminalNo"
          labelPosition="left"
          :config="{
            type: 'input',
            required: true,
            label: '终端编号：',
            isNum: true,
            width: '100%',
          }"
        >
        </TextInput> </el-form-item
      ><el-form-item prop="terminalSerialNumber">
        <TextInput
          v-model="dataForm.terminalSerialNumber"
          labelPosition="left"
          :config="{
            type: 'input',
            label: '终端序列号：',
            width: '100%',
          }"
        >
        </TextInput> </el-form-item
      ><el-form-item prop="terminalTypeValue">
        <TextInput
          @returnVal="returnVal"
          v-model="dataForm.terminalTypeValue"
          labelPosition="left"
          :config="{
            type: 'select',
            label: '协议类型：',
            required: true,
            width: '100%',
            option: dictList.protocolType,
            modelTextKey: 'terminalTypeKey',
          }"
          :optionConfig="{
            value: 'dictKey',
            label: 'dictValue',
          }"
        >
        </TextInput> </el-form-item
      ><el-form-item prop="deviceType">
        <TextInput
          v-model="dataForm.deviceType"
          labelPosition="left"
          :config="{
            type: 'select',
            label: '终端类型：',
            required: true,
            width: '100%',
            option: dictList.ProductTerminal,
          }"
        >
        </TextInput> </el-form-item
      ><el-form-item prop="manufactorTypeValue">
        <TextInput
          @returnVal="returnVal"
          v-model="dataForm.manufactorTypeValue"
          labelPosition="left"
          :config="{
            type: 'select',
            label: '终端供应商：',
            required: true,
            width: '100%',
            modelTextKey: 'manufactorTypeKey',
            option: dictList.terminalVendors,
          }"
          :optionConfig="{
            value: 'dictKey',
            label: 'dictValue',
          }"
        >
        </TextInput> </el-form-item
    ></el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">关闭</el-button>
      <el-button @click="submit" type="primary">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import TextInput from "@/components/SimpleTable/TextInput.vue";
import { addOrUpdateTerminal } from "@/api/vehicleEquipment";
export default {
  name: "TerminalAddOrEditDialog",
  components: { TextInput },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    detailData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    dictList: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    show(n) {
      if (n) {
        this.dataForm = _.cloneDeep(this.detailData);
      }
      this.dialogVisible = n;
    },
    dialogVisible(n) {
      if (!n) {
        this.$emit("update:show", false);
      }
    },
  },
  created() {},
  data() {
    return {
      dialogVisible: false,
      dataForm: {
        //id: null, //主键id
        terminalNo: "", //终端编号
        terminalTypeKey: "", //协议类型字典key
        terminalTypeValue: "", //协议类型字典value
        deviceType: "", //1.视频终端(OVP) 2.OBD终端 3.有线GPS 4.无线GPS 5.PFT 6.BSD 7.AEB
        manufactorTypeKey: "", //终端供应商字典key
        manufactorTypeValue: "", //终端供应商字典value
        terminalSerialNumber: "", //终端序列号
      },
      rules: {
        terminalNo: [
          { required: true, message: "请输入终端编号", trigger: "change" },
        ],
        terminalTypeValue: [
          { required: true, message: "请选择协议类型", trigger: "change" },
        ],
        manufactorTypeValue: [
          { required: true, message: "请选择终端供应商", trigger: "change" },
        ],
        deviceType: [
          { required: true, message: "请选择终端类型", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    returnVal(res) {
      this.dataForm[res.type] = res.data.code;
    },
    submit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          addOrUpdateTerminal(this.dataForm).then((res) => {
            if (res.flag == 1) {
              this.$emit("submit");
              this.dialogVisible = false;
              this.$message({
                type: "success",
                message: "提交成功",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 10px;
  overflow: hidden;
}
</style>
